<template>
  <div class="flex">
    <DashboardSidebar />

    <div class="flex-1 min-w-0">
      <div class="relative min-w-0 py-10 px-16 flex flex-col h-full">
        <DashboardHeader
          class="mb-16 relative z-20"
          @update:height="onHeaderHeightUpdate"
        />

        <main class="relative z-0">
          <slot />
        </main>

        <div
          v-if="hostingStatus && headerHeight"
          class="absolute inset-0 z-10 bg-black bg-opacity-50 flex justify-center pt-32 px-32"
          :style="{ top: `${headerHeight}px`}"
        >
          <div class="text-white flex-none">
            <div class="font-plex font-medium text-6xl">
              Hosting Status:
            </div>
            <div class="font-medium text-2xl">
              {{ userStore.user?.app_metadata?.hostingStatus }}
            </div>
            <VBtn
              v-if="!userStore.user?.app_metadata?.hostingStatus"
              class="text-black bg-lemon border-lemon mt-8"
              @click="router.push('/dashboard/user#podcast')"
            >
              Click Here to Enter Your RSS Feed
            </VBtn>
          </div>

          <div class="flex-auto">
            <img
              src="~/assets/images/graph-screenshot.png"
              class="w-full ml-8"
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useUserStore } from '~/store/user';

const userStore = useUserStore();
const router = useRouter();
const route = useRoute();

const headerHeight = ref(0);
function onHeaderHeightUpdate(height: number) {
  headerHeight.value = height;
}

const hostingStatus = computed(() => {
  if (route.path === '/dashboard' || route.path === '/dashboard/hosting') {
    return !(userStore.user?.app_metadata?.hostingStatus === 'active'
      && userStore.podcast);
  }

  return undefined;
});
</script>
