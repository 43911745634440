<template>
  <HeadlessPopover
    v-slot="{ close }"
    class="relative"
  >
    <HeadlessPopoverButton
      class="relative z-10 text-4xl w-12 h-12 rounded-full flex justify-center items-center"
      aria-label="Notification Menu"
    >
      <Icon :name="hasNewNotifications ? 'mdi:bell-badge' : 'mdi:bell'" />
    </HeadlessPopoverButton>

    <transition
      enter-active-class="transition duration-150 ease-out"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="transition duration-100 ease-in"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <HeadlessPopoverPanel class="absolute -top-4 -right-4 z-0 bg-white text-black border-2 border-light rounded-lg">
        <div class="flex px-4 pt-4">
          <div class="flex items-center">
            <div class="text-xl">
              Notifications
            </div>
          </div>

          <div class="w-12 h-12 ml-4" />
        </div>

        <hr class="mt-4 mx-4 border-light">

        <ul class="mt-4 pb-4">
          <li
            v-if="notifications.length === 0"
          >
            <div class="px-4 py-2 w-full text-left hover:bg-lighter text-gray select-none">
              No notifications
            </div>
          </li>
          <li
            v-for="notification of notifications"
            :key="notification.id"
          >
            <button
              class="px-4 py-2 w-full text-left hover:bg-lighter flex items-start"
              @click="onNotificationClick(notification)"
            >
              <div class="flex-grow">
                {{ notification.title }}
              </div>
              <div class="flex-none">
                <Icon
                  name="mdi:circle"
                  :class="notification.isRead ? 'text-light' : 'text-purple'"
                />
              </div>
            </button>
          </li>
        </ul>
      </HeadlessPopoverPanel>
    </transition>

    <VDialogPopup ref="notificationDialog">
      <template #header>
        <div v-if="currentNotification?.title">
          {{ currentNotification?.title }}
        </div>
      </template>

      <div
        v-if="currentNotification?.body"
        class="prose"
        v-html="currentNotification?.body"
      />
    </VDialogPopup>
  </HeadlessPopover>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';

import { VDialogPopup } from '#components';
import { useNotificationStore, Notification } from '@/store/notification';

const notificationStore = useNotificationStore();
const { notifications, hasNewNotifications } = storeToRefs(notificationStore);

const notificationDialog = ref<InstanceType<typeof VDialogPopup> | null>(null);

const currentNotification = ref<Notification | null>(null);
function onNotificationClick(notification: Notification) {
  currentNotification.value = notification;
  notificationDialog.value?.setIsOpen(true);
  notificationStore.markNotificationRead(notification.id);
}
</script>
