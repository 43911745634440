<template>
  <header
    ref="el"
    class="flex justify-between items-center w-full"
  >
    <div
      v-if="userPodcastInfo"
      class="min-w-0 mr-8"
    >
      <div class="font-plex font-medium text-3xl overflow-hidden text-ellipsis whitespace-nowrap">
        {{ userPodcastInfo.title }}
      </div>
      <div class="text-base text-gray overflow-hidden text-ellipsis whitespace-nowrap">
        {{ userPodcastInfo.desc }}
      </div>
    </div>

    <div class="" />

    <div class="flex space-x-6 relative">
      <NotificationMenu class="z-0" />
      <UserMenu class="z-10" />
    </div>
  </header>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';

import { useUserStore } from '@/store/user';

const userStore = useUserStore();
const { userPodcastInfo } = storeToRefs(userStore);

type Emits = {
  (e: 'update:height', height: number): void
};
const emit = defineEmits<Emits>();

const el = ref<HTMLDivElement | null>(null);

function onResize() {
  const height = (el.value?.getBoundingClientRect().bottom || 0)
    + (parseFloat(getComputedStyle(el.value!).marginBottom) / 2);
  emit('update:height', height);
}

let resizeObserver: ResizeObserver;
onMounted(() => {
  resizeObserver = new ResizeObserver(onResize);
  resizeObserver.observe(el.value!);

  onResize();
  setTimeout(() => {
    onResize();
  }, 0);
});

onBeforeUnmount(() => {
  resizeObserver.disconnect();
});
</script>
