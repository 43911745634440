<template>
  <aside class="bg-black h-screen">
    <div class="flex justify-center py-10">
      <NuxtLink
        to="/"
      >
        <img
          src="~/assets/images/podjourney-logo-light.svg"
          alt="PodJourney"
        >
      </NuxtLink>
    </div>

    <nav class="flex flex-col">
      <NuxtLink
        v-for="(item, i) of navItems"
        :key="i"
        :to="item.to"
        class="p-10 flex items-center font-bold text-base text-white"
        exact-active-class="bg-purple"
        :target="item.target"
      >
        <Icon
          :name="item.icon"
          class="text-4xl"
        />
        <span class="ml-6">
          {{ item.label }}
        </span>
      </NuxtLink>
    </nav>
  </aside>
</template>

<script setup lang="ts">
import { useUserStore } from '@/store/user';

const userStore = useUserStore();

const navItems = computed(() => {
  const navItems = [
    { to: '/dashboard', label: 'Dashboard', icon: 'mdi:home' },
    { to: '/dashboard/hosting', label: 'Hosting', icon: 'mdi:microphone-outline' },
    {
      to: 'https://podjourney.net/', label: 'Coaching', icon: 'mdi:account', target: '_blank'
    }
    // { to: '/dashboard/ads', label: 'Ad Management', icon: 'mdi:web' },
    // { to: '/dashboard/production', label: 'Production', icon: 'mdi:wrench-outline' },
    // { to: '/dashboard/marketing', label: 'Marketing', icon: 'mdi:star-outline' }
  ];

  if (userStore.isAdmin) {
    navItems.push({ to: '/dashboard/admin', label: 'Admin', icon: 'mdi:security' });
  }

  return navItems;
});
</script>
