<template>
  <HeadlessPopover
    v-slot="{ close }"
    class="relative"
  >
    <HeadlessPopoverButton
      class="relative z-10"
      aria-label="Account Menu"
      :as="VAvatar"
      :src="authData?.user?.image"
    />

    <transition
      enter-active-class="transition duration-150 ease-out"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="transition duration-100 ease-in"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <HeadlessPopoverPanel class="absolute -top-4 -right-4 z-0 bg-white text-black border-2 border-light rounded-lg">
        <div class="flex px-4 pt-4">
          <div>
            <div class="text-xl">
              {{ authData?.user?.name }}
            </div>
            <div class="text-xs text-gray">
              {{ authData?.user?.email }}
            </div>
          </div>

          <div class="w-12 h-12 ml-4" />
        </div>

        <hr class="mt-4 mx-4 border-light">

        <ul class="mt-4 pb-4">
          <li
            v-for="(menuItem, i) of menuItems"
            :key="i"
          >
            <button
              class="px-4 py-2 w-full text-left hover:bg-lighter"
              @click="menuItem.onClick($event, close)"
            >
              {{ menuItem.label }}
            </button>
          </li>
        </ul>
      </HeadlessPopoverPanel>
    </transition>
  </HeadlessPopover>
</template>

<script setup lang="ts">
import { VAvatar } from '#components';

const { data: authData, signOut } = useAuth();

const menuItems = [
  {
    label: 'User Settings',
    onClick: (e: MouseEvent, close: Function) => {
      useRouter().push('/dashboard/user');
      close();
    }
  },
  {
    label: 'Logout',
    onClick: (e: MouseEvent, close: Function) => {
      signOut({
        callbackUrl: '/'
      });
    }
  }
];
</script>
